<template>
  <view-card-template :loaded="loaded">
    <!-- <template #viewHeader>
      <h1>Add an Award</h1>
    </template> -->

    <template #cardTitle>
      {{
        ballotId === null
          ? "Add an Award"
          : "Edit Award"
      }}
    </template>

    <template #cardBody>
      <div
        class="d-flex flex-column"
        style="height: 100%;"
      >
        <v-divider></v-divider>
        <v-stepper
          v-model="currentPageNumber"
          class="d-flex flex-column"
          flat
        >
          <v-stepper-header>
            <!-- START Stepper Header -->
            <v-stepper-step
              :complete="currentPageNumber > 1"
              step="1"
            >
              Award Details
            </v-stepper-step>
            <v-divider dark></v-divider>
            <v-stepper-step
              :complete="currentPageNumber > 2"
              step="2"
            >
              Nominators & Voters
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :complete="customVotesEnabled && currentPageNumber > 3"
              step="3"
            >
              Award Types, Etc.
            </v-stepper-step>
            <v-divider v-show="customVotesEnabled"></v-divider>
            <v-stepper-step
              v-show="customVotesEnabled"
              step="4"
            >
              Custom Points
            </v-stepper-step>
          </v-stepper-header>
          <!-- END Stepper Header -->

          <v-stepper-items>
            <!-- START Page 1 -->
            <v-stepper-content step="1">
              <h5>Enter details about the award</h5>
              <v-form ref="page1">
                <v-row class="mt-1">
                  <v-col
                    cols="2"
                    style="max-width: 80px;"
                  >
                    <v-subheader>Title</v-subheader>
                  </v-col>
                  <v-col :lg="10">
                    <v-text-field
                      v-model="title"
                      style="max-width: 900px;"
                      label="Your Title"
                      :rules="[
                        (input) => { return input.length > 0 || 'Required'}
                      ]"
                      solo
                    />
                  </v-col>
                </v-row>
                <v-row class="my-0">
                  <v-col
                    cols="2"
                    style="max-width: 80px;"
                  >
                    <v-subheader>Type</v-subheader>
                  </v-col>
                  <v-col :lg="4">
                    <v-select
                      label="Type"
                      v-model="selectedType"
                      :items="ballotTypes"
                      item-text="name"
                      item-value="value"
                      :rules="[
                        (input) => { return input > -1 || 'Required'}
                      ]"
                      solo-inverted
                    />
                  </v-col>
                </v-row>
                <v-row class="my-0">
                  <v-col
                    cols="2"
                    style="max-width: 80px;"
                  >
                    <v-subheader>Sport</v-subheader>
                  </v-col>
                  <v-col :lg="4">
                    <v-select
                      label="Sport"
                      v-model="selectedSport"
                      :items="sportsList"
                      item-text="name"
                      item-value="globalSportId"
                      @change="changeSport"
                      :rules="[
                        (input) => { return input > -1 || 'Required'}
                      ]"
                      solo-inverted
                    />
                  </v-col>
                </v-row>
                <v-row class="my-0">
                  <v-col
                    cols="2"
                    style="max-width: 80px;"
                  >
                    <v-subheader>Season</v-subheader>
                  </v-col>
                  <v-col :lg="4">
                    <v-select
                      label="Season"
                      v-model="selectedSeason"
                      :items="seasonsList"
                      item-text="title"
                      item-value="title"
                      :rules="[
                        (input) => { return input.length > 0 || 'Required'}
                      ]"
                      solo-inverted
                    />
                  </v-col>
                </v-row>
                <v-row class="my-0">
                  <v-col
                    cols="2"
                    style="max-width: 80px;"
                  >
                    <v-subheader>Division</v-subheader>
                  </v-col>
                  <v-col :lg="4">
                    <v-select
                      label="Division"
                      v-model="selectedDivision"
                      :items="divisionsList"
                      item-text="title"
                      item-value="title"
                      solo-inverted
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>
            <!-- END Page 1 -->
            
            <!-- START Page 2 -->
            <v-stepper-content step="2">
              <h5>Select Voters and Nominators</h5>
              <v-data-table
                :headers="headers"
                :items="usersFiltered"
                :items-per-page="20"
                item-key="voterId"
                sort-by="lastName"
                group-by="school"
                class="elevation-1 ma-1"
                show-group-by
                dense
              >
                <template #top>
                  <v-row 
                    class="mx-2 mt-2 mb-3"
                    no-gutters
                  >
                    <v-col cols="4">
                      <v-text-field
                        @input="updateGroupCheckboxes"
                        v-model="staffSearch"
                        class="mx-2"
                        label="Filter by Staff"
                        hide-details
                        clearable
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-combobox
                        @change="updateGroupCheckboxes"
                        v-model="selectedSchool"
                        :items="userSchools"
                        class="mx-2"
                        label="Filter by School"
                        hide-details
                        clearable
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        @change="updateGroupCheckboxes"
                        v-model="selectedUserSport"
                        :items="userSports"
                        item-text="name"
                        item-value="globalSportId"
                        class="mx-2"
                        label="Filter by Sport"
                        :menu-props="{
                          offsetY: true,
                          bottom: true
                        }"
                        hide-details
                        clearable
                      />
                    </v-col>
                  </v-row>
                  
                </template>
                <template v-slot:[`group.header`]="{ group }">
                  <td>
                    <h4>{{ group }}</h4>
                  </td>
                  <td  v-if="selectedType!=4">
                    <div class="add-award-checkbox d-flex justify-center align-center">
                      <v-checkbox
                        v-model="getGroup(group).canNominate"
                        @change="toggleGroupCheckbox(getGroup(group), 'canNominate')"
                        hide-details
                      ></v-checkbox>
                    </div>
                  </td>
                  <td>
                    <div class="add-award-checkbox d-flex justify-center align-center">
                      <v-checkbox
                        v-model="getGroup(group).canVote"
                        @change="toggleGroupCheckbox(getGroup(group), 'canVote')"
                        hide-details
                      ></v-checkbox>
                    </div>
                  </td>
                  <td  v-if="selectedType!=4">
                    <div class="add-award-checkbox d-flex justify-center align-center">
                      <v-checkbox
                        v-model="getGroup(group).canBoth"
                        @change="toggleGroupCheckbox(getGroup(group), 'canBoth')"
                        hide-details
                      ></v-checkbox>
                    </div>
                  </td>
                </template>
                <template #item="{ item }">
                  <tr>
                    <td>
                      {{ `${item.lastName}, ${item.firstName}` }}
                    </td>
                    <td  v-if="selectedType!=4">
                      <div class="add-award-checkbox d-flex justify-center align-center">
                        <v-checkbox
                          v-model="item.canNominate"
                          @change="toggleVoterCheckbox(item, 'canNominate')"
                          hide-details
                        ></v-checkbox>
                      </div>
                    </td>
                    <td>
                      <div class="add-award-checkbox d-flex justify-center align-center">
                        <v-checkbox
                          v-model="item.canVote"
                          @change="toggleVoterCheckbox(item, 'canVote')"
                          hide-details
                        ></v-checkbox>
                      </div>
                    </td>
                    <td  v-if="selectedType!=4">
                      <div class="add-award-checkbox d-flex justify-center align-center">
                        <v-checkbox
                          v-model="item.canBoth"
                          @change="toggleVoterCheckbox(item, 'canBoth')"
                          hide-details
                        ></v-checkbox>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-stepper-content>
            <!-- END Page 2 -->

            <!-- START Page 3 -->
            <v-stepper-content step="3">
              <v-form ref="page3">
                <v-row v-if="selectedType!=4">
                  <v-col>
                    <v-btn
                      @click="openAwardTypesModal"
                      color="primary"
                    >
                      Select Award Types...
                    </v-btn>
                  </v-col>
                  <app-modal
                    :value="awardTypesModal"
                    :persistent="true"
                    header-text="Select Award Types"
                    width="auto"
                    min-width="500px"
                  >
                    <template #content>
                      <div 
                        class="px-2 mt-2"
                        style="max-height: 450px; overflow-y: scroll; scrollbar-width: thin;"
                      >
                        <div
                          v-for="(awardType, index) in sportAwardTypesModalCopy"
                          :key="index"
                        >
                          <v-checkbox
                            v-model="awardType.selected"
                            :label="awardType.title"
                            class="my-0"
                            hide-details="auto"
                          ></v-checkbox>
                        </div>
                      </div>
                    </template>
                    <template #action>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="cancelAwardTypesModal"
                        depressed
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        @click="applyAwardTypesModal"
                        class="ml-2"
                        color="primary"
                        depressed
                      >
                        Apply
                      </v-btn>
                    </template>
                  </app-modal>
                </v-row>
                <v-row v-else>
                  <v-col>
                    <v-btn
                      v-if="selectedType == 4"
                      @click="openAwardSchoolPollModal"
                      color="primary"
                    >
                      Select member schools 
                    </v-btn>
                    
                  </v-col>
                  <app-modal
                    :value="awardSchoolPollModal"
                    :persistent="true"
                    header-text="Select member schools"
                    width="auto"
                    min-width="500px"
                  >
                    <template #content>
                      <div 
                        class="px-2 mt-2"
                        style="max-height: 450px; overflow-y: scroll; scrollbar-width: thin;"
                      >
                        <div
                          v-for="(awardType, index) in userSchoolsCopy"
                          :key="index"
                        >
                          <v-checkbox
                            v-model="awardType.selected"
                            :label="awardType.title"
                            class="my-0"
                            hide-details="auto"
                          ></v-checkbox>
                        </div>
                      </div>
                    </template>
                    <template #action>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="cancelAwardSchoolPollModal"
                        depressed
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        @click="applyAwardSchoolModal"
                        class="ml-2"
                        color="primary"
                        depressed
                      >
                        Apply
                      </v-btn>
                    </template>
                  </app-modal>
                  
                </v-row>
                <div v-if="selectedType!=4">
                  <v-row
                    v-if="awards.length && selectedType!=4"
                    class="mt-3"
                    no-gutters
                  >
                    <v-col cols="3">
                      <h4>Award Types</h4>
                    </v-col>
                    <v-col cols="4">
                      <h4>Stat Groups</h4>
                    </v-col>
                    <v-col cols="2">
                      <h4>Nominations</h4>
                    </v-col>
                    <v-col cols="2">
                      <h4>Votes</h4>
                    </v-col>
                    <v-col cols="1">
                      <h4>Custom Points</h4>
                    </v-col>
                  </v-row>
                  <v-row
                    v-for="(award, index) in awards"
                    :key="index"
                    no-gutters
                  >
                    <!-- Award Type -->
                    <v-col
                      cols="3"
                      align-self="end"
                    >
                      <div
                        class="d-flex align-center"
                        style="height: 100%;"
                      >
                        {{ award.awardType.title }}
                      </div>
                    </v-col>
                    <!-- Stat Groups -->
                    <v-col
                      cols="4"
                      align-self="end"
                    >
                      <v-combobox
                        v-model="award.statGroups"
                        :items="sportSpecificStatGroups"
                        item-text="name"
                        item-value="id"
                        class="mr-4"
                        hide-details
                        small-chips
                        clearable
                        multiple
                        dense
                      />
                    </v-col>
                    <!-- Nominations -->
                    <v-col
                      cols="2"
                      align-self="end"
                    >
                      <v-text-field
                        v-model="award.numNominationsAllowed"
                        type="number"
                        hide-details="auto"
                        :rules="[
                          (input) => { return input >= 0 || 'Input must be non-negative'}
                        ]"
                        class="mt-0 mr-4 pt-0"
                      />
                    </v-col>
                    <!-- Votes -->
                    <v-col
                      cols="2"
                      align-self="end"
                    >
                      <v-text-field
                        v-model="award.numVotesAllowed"
                        type="number"
                        hide-details="auto"
                        :rules="[
                          (input) => { return input >= 0 || 'Input must be non-negative'}
                        ]"
                        class="mt-0 mr-4 pt-0"
                      />
                    </v-col>
                    <v-col
                    cols="1"
                    align-self="end"
                  >
                    <v-checkbox
                      v-model="award.customVotes"
                      type="number"
                      hide-details="auto"
                      class="mt-0 mr-4 pt-0"
                    />
                  </v-col>
                  </v-row>
                </div>
                <div v-else>
                  <v-row
                    v-if="awards.length"
                    class="mt-3"
                    no-gutters
                  >
                    <v-col cols="4">
                      <h4>School</h4>
                    </v-col>
                    <v-col cols="4">
                      <h4>Note</h4>
                    </v-col>
                  </v-row>
                  <v-row
                    v-for="(award, index) in selectedSchools"
                    :key="index"
                    no-gutters
                  >
                    <!-- Award Type -->
                    <v-col
                      cols="4"
                      align-self="end"
                    >
                      <div
                        class="d-flex align-center"
                        style="height: 100%;"
                      >
                        {{ award.title }}
                      </div>
                    </v-col>
                    <!-- Stat Groups -->
                    <v-col
                      cols="8"
                      align-self="end"
                    >
                      <v-text-field
                        v-model="award.note"
                        type="text"
                        hide-details="auto"
                        
                        class="mt-0 mr-4 pt-0"
                      />
                    </v-col>
                    <!-- Votes -->
                  </v-row>
                  <v-row>
                    <v-col
                      cols="4"
                      align-self="end"
                    >
                      <v-text-field
                        v-model="pollNumVotesAllowed"
                        label="Votes Allowed"
                        type="number"
                        hide-details="auto"
                        :rules="[
                          (input) => { return input >= 0 || 'Input must be non-negative'}
                        ]"
                        class="mt-0 mr-4 pt-0"
                      />
                    </v-col>
                  </v-row> 
                </div>
                <v-row>
                  <v-col>
                    <h4>Games Occurring Between</h4>
                    <v-row
                      class="mt-3"
                    >
                      <v-col
                        cols="4"
                      >
                        <div
                          style="height: 100%;"
                          class="d-flex align-center"
                        >
                          <app-date-picker
                            label="Start Date"
                            :date="gamesBetweenStartDate"
                            @updateDate="newDate => gamesBetweenStartDate = newDate"
                            :hide-details="false"
                            :rules="[
                              (input) => { return input.length > 0 || 'Required'},
                              (input) => { return !gamesBetweenEndDate.length > 0
                                || new Date(gamesBetweenStartDate) <= new Date(gamesBetweenEndDate)
                                || 'Start Date must be before End Date'}
                            ]"
                          />
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div
                          style="height: 100%;"
                          class="d-flex align-center"
                        >
                          <app-date-picker
                            label="End Date"
                            :date="gamesBetweenEndDate"
                            @updateDate="newDate => gamesBetweenEndDate = newDate"
                            :hide-details="false"
                            :rules="[
                              (input) => { return input.length > 0 || 'Required'},
                              (input) => {return !gamesBetweenStartDate.length > 0 
                                || new Date(gamesBetweenStartDate) <= new Date(gamesBetweenEndDate)
                                || 'End Date must be after Start Date'}
                            ]"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4>Options</h4>
                    <!-- Rank Votes -->
                    <v-row no-gutters>
                      <v-col cols="auto">
                        <v-checkbox
                          v-model="rankVotes"
                          label="Rank Votes"
                          class="my-0"
                          hide-details="auto"
                        />
                      </v-col>
                    </v-row>
                    <!-- Allow Votes For Own Team -->
                    <v-row no-gutters>
                      <v-col cols="auto">
                        <v-checkbox
                          v-model="allowVotesForOwnTeam"
                          label="Allow Votes for Own Team"
                          class="my-0"
                          hide-details="auto"
                        />
                      </v-col>
                    </v-row>
                    <!-- defien default option to show conf only-->
                    <v-row no-gutters>
                      <v-col cols="auto">
                        <v-checkbox
                          v-model="confOnly"
                          label="Stats: Show conference only"
                          class="my-0"
                          hide-details="auto"
                        />
                      </v-col>
                    </v-row>
                    <!-- Show Nomination Expands -->
                    <v-row no-gutters>
                      <v-col cols="auto">
                        <v-checkbox
                          v-model="showNominationExpands"
                          label="Default Supporting Info Sections for Nominations to Open State"
                          class="my-0"
                          hide-details="auto"
                        />
                      </v-col>
                    </v-row>
                    <!-- Default Number of Displayed Nominations Per Award Type -->
                    <v-row no-gutters class="mt-1">
                      <v-col cols="auto">
                        <div class="d-flex align-center">
                          <v-select
                            v-model="numDisplayedNominations"
                            :items="[
                              { text: '5', value: 5 },
                              { text: '10', value: 10 },
                              { text: '15', value: 15 },
                              { text: 'All', value: -1 }
                            ]"
                            class="my-0 py-0 mr-2"
                            style="max-width: 80px;"
                            hide-details="auto"
                          />
                          <div class="v-label theme--light">Default Number of Displayed Nominations Per Award Type</div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4>Custom Nomination Field</h4>
                    <!-- Use Custom Field -->
                    <v-row no-gutters>
                      <v-col cols="auto">
                        <div class="d-flex align-center mr-4" style="height: 100%;">
                          <v-checkbox
                            v-model="useCustomField"
                            label="Enable Custom Field"
                            class="my-0"
                            hide-details
                          />
                        </div>
                      </v-col>
                      <v-col>
                        <div
                          class="d-flex align-center"
                          style="height: 75%; max-width: 300px;"
                        >
                          <v-text-field
                            v-model="customFieldLabel"
                            label="Custom Field Label"
                            :disabled="!useCustomField"
                            clearable
                            hide-details
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4 class="mb-1">Nominations Page Display Text</h4>
                    <v-row>
                      <v-col>
                        <ckeditor
                          :editor="nominationsPageDisplayText.editor"
                          v-model="nominationsPageDisplayText.editorData"
                          :config="nominationsPageDisplayText.editorConfig"
                        ></ckeditor>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4 class="mb-1">Voting Page Display Text</h4>
                    <v-row>
                      <v-col>
                        <ckeditor
                          :editor="votingPageDisplayText.editor"
                          v-model="votingPageDisplayText.editorData"
                          :config="votingPageDisplayText.editorConfig"
                        ></ckeditor>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="d-flex align-center">
                      <h4>Ballot Phases</h4>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="mx-1"
                            color="blue darken-1"
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>Ballot phase will not auto-update after being manually set.</span>
                      </v-tooltip>
                    </div>
                    <v-row
                      v-if="ballotPhaseOverrides.length > 0"
                      no-gutters
                    >
                      <v-spacer/>
                      <v-col cols="auto">
                        <div class="d-flex align-center">
                          <div
                            v-for="(ballotPhaseOverride, index) in ballotPhaseOverrides"
                            :key="index"
                            class="d-flex align-center"
                          >
                            <v-checkbox
                              @change="(newValue) => updatePhaseCheckboxes(newValue, ballotPhaseOverride)"
                              v-model="ballotPhaseOverride.enabled"
                              :label="ballotPhaseOverride.name"
                              class="mt-0 pt-0"
                              color="primary"
                              hide-details
                            />
                            <v-icon
                              v-if="index < ballotPhaseOverrides.length - 1"
                              class="mx-2"
                            >
                              mdi-arrow-right
                            </v-icon>
                          </div>
                        </div>
                      </v-col>
                      <v-spacer/>
                    </v-row>
                    <v-row>
                      <v-spacer/>
                      <v-col cols="4">
                        <div
                          style="height: 100%;"
                          class="d-flex align-start"
                        >
                          <app-date-picker
                            type="datetime"
                            label="Nominating Start"
                            :date="nominationPhaseStartDate"
                            @updateDate="newDate => nominationPhaseStartDate = newDate"
                            hide-details="auto"
                            :rules="[
                              (input) => {return getPhaseRangeRule('Nomination', 'Start Date')},
                            ]"
                          />
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div
                          style="height: 100%;"
                          class="d-flex align-start"
                        >
                          <app-date-picker
                            type="datetime"
                            label="Nominating End"
                            :date="nominationPhaseEndDate"
                            @updateDate="newDate => nominationPhaseEndDate = newDate"
                            hide-details="auto"
                            :rules="[
                              (input) => {return getPhaseRangeRule('Nomination', 'End Date')}
                            ]"
                          />
                        </div>
                      </v-col>
                      <v-spacer/>
                    </v-row>
                    <!-- Voting Date Range -->
                    <v-row>
                      <v-spacer/>
                      <v-col cols="4">
                        <div
                          style="height: 100%;"
                          class="d-flex align-start"
                        >
                          <app-date-picker
                            type="datetime"
                            label="Voting Start"
                            :date="votingPhaseStartDate"
                            @updateDate="newDate => votingPhaseStartDate = newDate"
                            hide-details="auto"
                            :rules="[
                              (input) => {return getPhaseRangeRule('Voting', 'Start Date')},
                            ]"
                          />
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div
                          style="height: 100%;"
                          class="d-flex align-start mb-2"
                        >
                          <app-date-picker
                            type="datetime"
                            label="Voting End"
                            :date="votingPhaseEndDate"
                            @updateDate="newDate => votingPhaseEndDate = newDate"
                            hide-details="auto"
                            :rules="[
                              (input) => {return getPhaseRangeRule('Voting', 'End Date')}
                            ]"
                          />
                        </div>
                      </v-col>
                      <v-spacer/>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-form ref="page4">
                <v-expansion-panels
                  multiple
                  focusable
                >
                <v-expansion-panel
                    v-for="(item,i) in awardsVotes"
                    :key="i"
                  >
                    <v-expansion-panel-header>
                      {{ item.awardType.title }} ({{ item.numVotesAllowed}} Votes Allowed)
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      
                        <div
                          v-for="vote,idx in item.VoteRank"
                          :key="vote.id"
                          class="my-1"
                          width="100%"
                          hover
                        >
                          <v-row no-gutters>
                            <v-col cols="6">
                              {{ cardinalNumber(idx + 1) }} Place
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                v-model="vote.points"
                                type="number"
                                label="Points"
                                min="0"
                                clearable
                                
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                    </v-expansion-panel-content>

                  </v-expansion-panel>
                  </v-expansion-panels>
                </v-form>
            </v-stepper-content>
            <!-- END Page 3 -->
          </v-stepper-items>
        </v-stepper>

        <!-- START Page Actions -->
        <!-- Manual padding on this view because the stepper interferes with the usual padding on the container. -->
        <v-card-actions
          class="px-6 py-4"
          style="margin-top: auto;"
        >
          <v-spacer v-if="currentPageNumber === 1"></v-spacer>
          <v-btn
            @click="$router.push({ path: '/admin/award-management' })"
            depressed
          >
            Cancel
          </v-btn>
          <v-spacer v-if="currentPageNumber !== 1"></v-spacer>

          <v-btn
            v-if="currentPageNumber !== 1"
            @click="decrementPage"
            color="primary"
          >
            Back
          </v-btn>
          <v-btn
            v-if="currentPageNumber !== totalPageCount"
            @click="incrementPage"
            color="primary"
          >
            Next
          </v-btn>
          <v-btn
            v-if="currentPageNumber === totalPageCount"
            @click="finishAward"
            :loading="submitBallotLoading"
            color="primary"
          >
            Finish
          </v-btn>
        </v-card-actions>
        <!-- END Page Actions -->
        
      </div>
    </template>
  </view-card-template>
</template>

<script>
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'
import AppModal from "@/components/app/AppModal"
import AppDatePicker from "@/components/app/AppDatePicker"
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Identity from "@/app/Identity"

import Controllers from "@/data/controllers"

import moment from 'moment-timezone'

export default {
  name: "AwardForm",

  components: {
    ViewCardTemplate,
    AppModal,
    AppDatePicker,
    ckeditor: CKEditor.component
  },

  props: {
    ballotId: {
      type: Number,
      required: false,
      default: null
    },
  },

  data: () => ({
    loaded: false,
    submitBallotLoading: false,
    customVotesEnabled: false,
    dragging: false,
    awardsVotes: [],
    ballot: null,

    // Page Action State
    currentPageNumber: 1, // 1-indexed
    totalPageCount: 3,

    // Page 1 State
    title: "",
    selectedType: -1,
    selectedSport: -1,
    selectedSeason: "",
    selectedDivision: "",

    sportChanged: false,

    titleValidation: true,
    typeValidation: true,
    sportValidation: true,
    seasonValidation: true,
    divisionValidation: true,

    ballotTypes: [],
    sportsList: [],
    seasonsList: [],
    divisionsList: [],
    pollNumVotesAllowed: 0,
    // Page 2 State
    usersList: [],
    checkboxGroups: [],
    staffSearch: "",
    selectedSchool: null,
    selectedUserSport: null,
   
    // Page 3 State
    awards: [],
    selectedSchools: [],
    sportAwardTypes: [],
    sportAwardTypesModalCopy: [],
    userSchoolsCopy: [],
    awardTypesModal: false,
    maskedAwardTypeIdsByGlobalSportId: null,
    sportSpecificStatGroups: [],
    gamesBetweenStartDate: null,
    gamesBetweenEndDate: null,
    ballotPhaseOverrides: [],
    nominationPhaseStartDate: null,
    nominationPhaseEndDate: null,
    votingPhaseStartDate: null,
    votingPhaseEndDate: null,
    rankVotes: false,
    allowVotesForOwnTeam: false,
    confOnly: false,
    showNominationExpands: false,
    numDisplayedNominations: 5,
    useCustomField: false,
    customFieldLabel: null,
    nominationsPageDisplayText: {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
          removePlugins: [
          'CKFinderUploadAdapter',
          'CKFinder',
          'EasyImage',
          'Image',
          'ImageCaption',
          'ImageStyle',
          'ImageToolbar',
          'ImageUpload',
          'MediaEmbed',
          'BlockQuote',
          'Table',
          'TableToolbar',
        ],
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'undo',
            'redo',
          ]
        },
      },
    },
    awardSchoolPollModal: false,
    votingPageDisplayText: {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
          removePlugins: [
          'CKFinderUploadAdapter',
          'CKFinder',
          'EasyImage',
          'Image',
          'ImageCaption',
          'ImageStyle',
          'ImageToolbar',
          'ImageUpload',
          'MediaEmbed',
          'BlockQuote',
          'Table',
          'TableToolbar',
        ],
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'undo',
            'redo',
          ]
        },
      },
    },    
  }),

  computed: {
    conferences() {
      return Identity.GetConferences()
    },
    adminInfo() {
      return Identity.GetAdmin()
    },
    selectedAwardTypes() {
      return this.sportAwardTypes.filter(awardType => awardType.selected)
    },

    userSchools() {
      const includedSchools = new Set()
      this.usersList.forEach(user => includedSchools.add(user.school))
      return Array.from(includedSchools)
    },

    userSports() {
      const globalSportIds = new Set()
      this.usersList
        .filter(user => user.globalSportId != null)
        .forEach(user => globalSportIds.add(user.globalSportId))
      return Array.from(globalSportIds)
        .map(globalSportId => this.sportsList.find(s => s.globalSportId == globalSportId))
    },

    usersFiltered() {
      return this.usersList
        .filter(user => !user.disabled)
        .filter(user => {
          // Allow for `first last` or `last first`
          return this.staffSearch && this.staffSearch.length
          ? `${user.firstName.toLocaleLowerCase()} ${user.lastName.toLocaleLowerCase()} ${user.firstName.toLocaleLowerCase()}`.includes(this.staffSearch.toLocaleLowerCase())
          : true
        })
        .filter(user => this.selectedSchool ? user.school == this.selectedSchool : true)
        .filter(user => this.selectedUserSport ? user.globalSportId == this.selectedUserSport : true)
    },
    headers() {
      return this.selectedType == 4 ? [
        {
          text: 'Staff grouped by School',
          align: 'start',
          // value: 'school',
          groupable: false,
          sortable: false,
        },
        // {
        //   text: 'Neither',
        //   align: 'center',
        //   groupable: false,
        //   sortable: false,
        // },
        {
          text: 'Vote',
          align: 'center',
          groupable: false,
          sortable: false,
        },
       ] : [
        {
          text: 'Staff grouped by School',
          align: 'start',
          // value: 'school',
          groupable: false,
          sortable: false,
        },
        // {
        //   text: 'Neither',
        //   align: 'center',
        //   groupable: false,
        //   sortable: false,
        // },
        {
          text: 'Nominate',
          align: 'center',
          groupable: false,
          sortable: false,
        },
        {
          text: 'Vote',
          align: 'center',
          groupable: false,
          sortable: false,
        },
        {
          text: 'Both',
          align: 'center',
          groupable: false,
          sortable: false,
        },
      ] 
    },
    selectedAwards() {
      return this.awards.filter(award => award.customVotes)
    }
  },
  watch: {
    awards: {
      handler(newValue) {
        if (newValue.filter(award => award.customVotes).length > 0) {
          this.totalPageCount = 4
          this.customVotesEnabled = true
        } else {
          this.totalPageCount = 3
          this.customVotesEnabled = false
        }
      },
      deep: true
    }
  },
  methods: {
    async addAwardNomination(awardId, ballotId, name, note, conference) {
      const n = {
        "id": 0,
        "awardId": awardId,
        "ballotId": ballotId,
        "firstName": name,
        "lastName": note,
        "hometown": "",
        "highSchool": "",
        "jersey": "",
        "position": "",
        "classYear": null,
        "customField": null,
        "supportingInformation": "",
        "teamName": conference,
        "teamId": 0,
        "apiPlayerName": null
      }
      const res = await Controllers.NominationController.CreateCopyNomination(n)
      if(res && !res.hasError) {
         this.$root.showSnackbar('Nomination submitted successfully!', "success", 5000)
      } else {
         this.$root.showSnackbar('There was an error submitting your nomination.', "error", 5000)
      }
    },
    secuentialSum(n){
      let sum = 0; // initialize to zero
      for (var x = n; x > 0; x--) {
        sum += x; 
      }  
      return sum    
    },
    totalPointPerAward(i,y){
      let sum = 0;
      if(this.awardsVotes[i] != undefined) {
        
        this.awardsVotes[i].VoteRank.forEach((a,idx) => {
          if(idx != y) {
            sum += Number(a.points)
          }

        })
      }
      return sum
    },
    cardinalNumber(n) {
      const pr = new Intl.PluralRules("en-US", { type: "ordinal" });
      const suffixes = new Map([
        ["one", "st"],
        ["two", "nd"],
        ["few", "rd"],
        ["other", "th"],
      ]);
      
        const rule = pr.select(n);
        const suffix = suffixes.get(rule);
        return `${n}${suffix}`;
    },
    async incrementPage() {
      if(this.currentPageNumber == 1 && this.sportChanged && this.selectedType !== 4 ) {
        this.sportChanged = false
        this.awards = []
        await this.getSportInfo(this.selectedSport, true)
      }
      if(this.currentPageNumber == 1 && this.selectedType == 4 ) {
        await this.getSportInfo(this.selectedSport, true)
        this.userSchoolsCopy = this.userSchools.map(us => {
          return {
            selected: false,
            title: us,
            note: ''
          }
        })
        if (this.ballot )
        this.ballot.awards.forEach(award => {
          this.pollNumVotesAllowed = award.numVotesAllowed;

          if (award.awardType.title == 'Preseason Poll' && this.selectedType == 4) {
            this.selectedSchools = award.nominations.map(n => {
              const school = this.userSchoolsCopy.find(u => u.title == n.firstName)
              if (school) {
                school.note = n.lastName
                school.selected = true
              }
              return {
                title: n.firstName,
                note: n.lastName,
                selected: true
              }
            })
          }
        })
      }
      if (this.currentPageNumber == 3) {
        this.awardsVotes = this.awards.filter(award => award.customVotes).map((item) => {

          const voteRank = []
          let numVotesAllowed = Number(item.numVotesAllowed)  + 1
          for (let i = 1; i <=  Number(item.numVotesAllowed); i++) {
            numVotesAllowed = numVotesAllowed - 1
            voteRank.push({
              id: i,
              points: item.VoteRank[i-1] != null ? item.VoteRank[i-1].points : item.customVotes ? 1 : numVotesAllowed <= 0 ? 0 : numVotesAllowed,
              rank: i
            })
          }
          item.VoteRank = voteRank
          return item
        })
      }
      if(this.currentPageNumber === 1 && (this.$refs.page1.validate() || this.$root.showSnackbar('Failed to validate required fields.', "error", 5000)))
        ++this.currentPageNumber
      else if (
        this.customVotesEnabled && this.currentPageNumber === 3 && 
        (this.$refs.page3.validate() || this.$root.showSnackbar('Failed to validate required fields.', "error", 5000))
      )
        ++this.currentPageNumber
      else if(this.currentPageNumber !== 1 && this.currentPageNumber !== 3) ++this.currentPageNumber
    },

    decrementPage() {
      --this.currentPageNumber
    },

    async finishAward() {
      const ref = this.customVotesEnabled ? this.$refs.page4.validate() : this.$refs.page3.validate()
      if(ref || this.$root.showSnackbar('Failed to validate required fields.', "error", 5000)) {
        this.submitBallotLoading = true

        const ballotSubmission = {
          id: this.ballot ? this.ballot.id : 0,
          conferenceId: this.ballot ? this.ballot.conferenceId : 0,

          // Page 1
          title: this.title,
          ballotType: this.selectedType, 
          globalSportId: this.selectedSport,
          season: this.selectedSeason,
          division: this.selectedDivision,

          // Page 2
          voters: this.usersList
            .filter(user => user.canVote)
            .map(user => Controllers.UserController.toUserDtoSimpleAdmin(user)),
          nominators: this.usersList
            .filter(user => user.canNominate)
            .map(user => Controllers.UserController.toUserDtoSimpleAdmin(user)),
          
          // Page 3
          // Games Occuring Between
          gamesOccuringBetweenStartDate: moment.utc(this.gamesBetweenStartDate).tz("America/New_York").toDate(),
          gamesOccuringBetweenEndDate: moment.utc(this.gamesBetweenEndDate).tz("America/New_York").toDate(),

          // Options
          rankVotes: this.rankVotes,
          allowVotesForOwnTeam: this.allowVotesForOwnTeam,
          confOnly: this.confOnly,
          showNominationExpands: this.showNominationExpands,
          numDisplayedNominations: this.numDisplayedNominations,

          // Custom Nomination Field
          useCustomField: this.useCustomField,
          customFieldLabel: this.customFieldLabel,

          // WYSIWYG
          nominationsPageDescription: this.nominationsPageDisplayText.editorData,
          votingPageDescription: this.votingPageDisplayText.editorData,

          // Ballot Phases + Overrides
          nominationPhaseStartDate: this.nominationPhaseStartDate == null ? null : moment.utc(this.nominationPhaseStartDate).tz("America/New_York").toDate(),
          nominationPhaseEndDate: this.nominationPhaseEndDate == null ? null : moment.utc(this.nominationPhaseEndDate).tz("America/New_York").toDate(),
          votingPhaseStartDate: this.votingPhaseStartDate == null ? null : moment.utc(this.votingPhaseStartDate).tz("America/New_York").toDate(),
          votingPhaseEndDate: this.votingPhaseEndDate == null ? null : moment.utc(this.votingPhaseEndDate).tz("America/New_York").toDate(),
          phaseManuallyOverriden: this.ballotPhaseOverrides.find(phase => phase.enabled) != null,
          overridePhase: this.ballotPhaseOverrides.find(phase => phase.enabled) ? this.ballotPhaseOverrides.find(phase => phase.enabled).value : null,

          nominatorsNotified: this.ballot ? this.ballot.nominatorsNotified : false,
          votersNotified: this.ballot ? this.ballot.votersNotified : false,

          // Award Types
          
          awards: this.awards.map(a => {
            if (a.awardType.title == 'Preseason Poll' && this.selectedType == 4) {
              a.numVotesAllowed = this.pollNumVotesAllowed
            }
            a.nominations = a.nominations.map(n => {
              const school = this.selectedSchools.find(s => s.title == n.firstName)
              if (school) {
                n.firstName = school.title
                n.lastName = school.note
              }
              return n
            })
            return {
              ...a,
              results: a.results ? a.results : [],
              voteRank: a.customVotes ? a.VoteRank : [],
              customVotes: a.customVotes,
              VoteRank: a.customVotes ? a.VoteRank : []
            }
          }), // TODO

        }

        let success = false
        const conference = this.conferences.find(c => c.id == this.adminInfo.conferenceId)
        if(this.ballotId === null) {

          // Creating a new Award.
          const res = await Controllers.BallotController.AdminCreateBallot(ballotSubmission)
          success = res && !res.hasError && res.data
          if(success) this.$root.showSnackbar('Award created successfully!', 'success', 5000)
          else this.$root.showSnackbar('Failed to create award.', 'error', 5000)
          // Add new nominations for preseason poll
          const ballotId =  res.data.id
          const awardId = res.data.awards[0].id
          this.selectedSchools.forEach(async(element) => {
            await this.addAwardNomination(awardId, ballotId, element.title, element.note, conference?.name)
          });
        }
        else {
          // Editing an existing Award.

          const res = await Controllers.BallotController.AdminUpdateBallot(ballotSubmission)
          success = res && !res.hasError
          let newNominations = []
          let removedNominations = []
          let updatedNominations = []
          const awardTitle = res.data.awards[0].awardType.title
          const ballotId =  res.data.id
          const awardId = res.data.awards[0].id
          // Add new nominations for preseason poll that are not in nominations and remove values that was removed from list 
          if (awardTitle == 'Preseason Poll' && this.selectedType == 4) {
            const conference = this.conferences.find(c => c.id == this.adminInfo.conferenceId)
            this.ballot.awards.forEach(award => {
              this.selectedSchools.forEach(obj => {
                var found = false;
                // Check if object with same name exists in jsonArray
                award.nominations.forEach(jsonObj => {
                  if (jsonObj.firstName === obj.title) {
                    found = true;
                    updatedNominations.push(jsonObj)

                  }
                });
                // If not found, add to newNominations
                if (!found) {
                  newNominations.push(obj);
                }
              });
              award.nominations.forEach(jsonObj => {
                if (!this.selectedSchools.find(s => s.title == jsonObj.firstName)) {
                  removedNominations.push(jsonObj)
                }
              }); 
            });
            
            newNominations.forEach(async(element) => {
              await this.addAwardNomination(awardId, ballotId, element.title, element.note, conference?.name)
            });
            removedNominations.forEach(async(element) => {
              await Controllers.NominationController.AdminDeleteNomination(element.id)
            });
            updatedNominations.forEach(async(element) => {
              await Controllers.NominationController.AdminUpdateNomination(element)
            });
          }
          if(success) this.$root.showSnackbar('Award updated successfully!', 'success', 5000)
          else this.$root.showSnackbar('Failed to update award.', 'error', 5000)
        }

        this.submitBallotLoading = false
        if(success) this.$router.push({ name: "AwardManagement" })
      }
    },

    filterToLower(value, search, item) {
      return search != null
        && item != null
        && `${item.firstName} ${item.lastName}`
            .toString()
            .toLocaleLowerCase()
            .includes(search.toString().toLocaleLowerCase())
    },

    getGroup(schoolName) {
      // Find is guaranteed to succeed
      return this.usersList
        .find(user => user.school === schoolName)
        .checkboxGroup
    },

    toggleGroupCheckbox(group, toggledField) {
      const filteredGroupUsers = this.usersFiltered.filter(u => u.checkboxGroup == group)
      
      if(toggledField === "canNominate") {
        filteredGroupUsers.forEach(user => {
          user.canNominate = group.canNominate
        })
      }
      else if(toggledField === "canVote") {
        filteredGroupUsers.forEach(user => {
          user.canVote = group.canVote
        })
      }
      else { // toggledField === "canBoth"
        group.canNominate = group.canBoth
        group.canVote = group.canBoth

        filteredGroupUsers.forEach(user => {
          user.canBoth = group.canBoth
          user.canNominate = group.canBoth
          user.canVote = group.canBoth
        })
      }

      if(toggledField === "canNominate" || toggledField === "canVote") {
        group.canBoth = group.canNominate && group.canVote
        filteredGroupUsers.forEach(user => {
          user.canBoth = group.canBoth
        })
      }
    },

    toggleVoterCheckbox(user, toggledField) {
      // NOTE: I did this without counters on my first pass (where
      // I did the O(n) counting on each toggle to test if a whole column
      // is checked), and it didn't scale in my testing with 200 checkboxes
      // alone on a testing page. It may work for the table because <= 20 will
      // be shown, but I don't care to risk it.

      // NOTE 2 (10/17/2022 - SCORE2-405)
      // Just now adding in filter by sport, and the group checkboxes need
      // to toggle a subset of the group. The counter logic is too expensive to 
      // update at the moment. Put in the work later if/when it actually becomes a problem.
      if(toggledField === "canBoth") {
        user.canNominate = user.canBoth
        user.canVote = user.canBoth
      }
      else {
        user.canBoth = user.canNominate && user.canVote
      }

      this.updateGroupCheckbox(user.checkboxGroup)
    },

    updateGroupCheckboxes() {
      this.checkboxGroups.forEach(checkboxGroup => this.updateGroupCheckbox(checkboxGroup))
    },

    updateGroupCheckbox(checkboxGroup) {
      const filteredUsersInGroup = this.usersFiltered.filter(u => u.checkboxGroup == checkboxGroup)
      const filteredUsersInGroupSet = new Set(filteredUsersInGroup.map(u => u.id))

      const numCanNominate =
        checkboxGroup.users
          .filter(u => filteredUsersInGroupSet.has(u.id))
          .filter(u => u.canNominate)
          .length
      checkboxGroup.canNominate = numCanNominate == filteredUsersInGroup.length
      const numCanVote =
        checkboxGroup.users
          .filter(u => filteredUsersInGroupSet.has(u.id))
          .filter(u => u.canVote)
          .length
      checkboxGroup.canVote = numCanVote == filteredUsersInGroup.length
      const numCanBoth =
        checkboxGroup.users
          .filter(u => filteredUsersInGroupSet.has(u.id))
          .filter(u => u.canBoth)
          .length
      checkboxGroup.canBoth = numCanBoth == filteredUsersInGroup.length
    },

    openAwardTypesModal() {
      Object.assign(this.sportAwardTypesModalCopy, this.sportAwardTypes)
      this.awardTypesModal = true
    },

    openAwardSchoolPollModal() {
      this.awardSchoolPollModal = true
    },

    cancelAwardSchoolPollModal() {
      this.awardSchoolPollModal =  false
    },

    cancelAwardTypesModal() {
      this.awardTypesModal = false
    },

    applyAwardTypesModal() {
      Object.assign(this.sportAwardTypes, this.sportAwardTypesModalCopy)
      this.awardTypesModal = false

      // Remove awards based on which award types are no longer selected.
      const selectedAwardTypeIdSet = new Set(this.selectedAwardTypes.map(at => at.id))
      this.awards = this.awards.filter(award => selectedAwardTypeIdSet.has(award.awardType.id))

      // Add awards based on which award types are now selected.
      const awardsAwardTypeIdSet = new Set(this.awards.map(award => award.awardType.id))
      this.selectedAwardTypes.forEach(awardType => {
        if(!awardsAwardTypeIdSet.has(awardType.id))
          this.awards.push({
            id: 0,
            ballotId: this.ballotId ? this.ballotId : 0,
            awardTypeId: awardType.id,
            awardType,
            numNominationsAllowed: 0,
            numVotesAllowed: 0,
            statGroups: awardType.statGroups ? awardType.statGroups : [],
            nominations: [],
          })
      })
      
    },

    applyAwardSchoolModal() {
      const sport = this.sportAwardTypes.filter(sport => sport.title.includes('Poll'))
      sport[0].selected = true
      // Object.assign(this.sportAwardTypes, this.sportAwardTypesModalCopy)
      this.awardSchoolPollModal = false

      // // Remove awards based on which award types are no longer selected.
      const selectedAwardTypeIdSet = new Set(this.selectedAwardTypes.map(at => at.id))
      this.awards = this.awards.filter(award => selectedAwardTypeIdSet.has(award.awardType.id))
      this.selectedSchools = this.userSchoolsCopy.filter(us => us.selected)
      // Add awards based on which award types are now selected.
      const awardsAwardTypeIdSet = new Set(this.awards.map(award => award.awardType.id))
      if (this.ballotId == null)
        this.selectedAwardTypes.forEach(awardType => {
          if(!awardsAwardTypeIdSet.has(awardType.id)){
            
            this.awards.push({
              id: 0,
              ballotId: this.ballotId ? this.ballotId : 0,
              awardTypeId: awardType.id,
              awardType,
              numNominationsAllowed: 0,
              numVotesAllowed: 0,
              statGroups: awardType.statGroups ? awardType.statGroups : [],
              nominations: [],
            })
          }
        })
      
    },

    updatePhaseCheckboxes(newValue, updatedPhase) {
      if(newValue) {
        this.ballotPhaseOverrides.forEach(phase => {
          // Disable all other phases
          if(phase.value != updatedPhase.value) phase.enabled = false
        })
        // UI won't update without splice or replacing the array.
        // This isn't an issue in Vue 3 lmao.
        this.ballotPhaseOverrides = [...this.ballotPhaseOverrides]
      }
    },

    getPhaseRangeRule(phaseName, label) {
      // moment(date).add(numDays, 'days').format("YYYY-MM-DD")
      // nomStart <= nomEnd < voteStart <= voteEnd

      const nomStart = this.nominationPhaseStartDate == null ? null : new Date(this.nominationPhaseStartDate)
      const nomEnd = this.nominationPhaseEndDate == null ? null : new Date(this.nominationPhaseEndDate)
      const voteStart = this.votingPhaseStartDate == null ? null : new Date(this.votingPhaseStartDate)
      const voteEnd = this.votingPhaseEndDate == null ? null : new Date(this.votingPhaseEndDate)

      if(phaseName == "Nomination") {
        if(label == "Start Date") {
          if(nomStart != null) {
            if(nomEnd != null && nomEnd < nomStart)
              return "Nomination Start Date must not be after Nomination End Date"
            else if((voteStart != null && voteStart < nomStart) || (voteEnd != null && voteEnd < nomStart))
              return "Nomination phase must be before Voting phase"
          }
        }
        else if(label == "End Date") {
          if(nomEnd != null ) {
            if(nomStart != null && nomStart > nomEnd)
              return "Nomination End Date must not be before Nomination Start Date"
            else if ((voteStart != null && voteStart < nomEnd) || (voteEnd != null && voteEnd < nomEnd))
              return "Nomination phase must be before Voting phase"
          }
        }
      }
      else if(phaseName == "Voting") {
        if(label == "Start Date") {
          if(voteStart != null) {
            if(voteEnd != null && voteEnd < voteStart)
              return "Voting Start Date must not be after Voting End Date"
            else if((nomStart != null && nomStart > voteStart) || (nomEnd != null && nomEnd > voteStart))
              return "Voting phase must be after Nomination phase"
          }
        }
        else if(label == "End Date") {
          if(voteEnd != null) {
            if(voteStart != null && voteStart > voteEnd)
              return "Voting End Date must not be before Voting Start Date"
            if((nomStart != null && nomStart > voteEnd) || (nomEnd != null && nomEnd > voteEnd))
              return "Voting phase must be after Nomination phase"
          }
        }
      }

      return true
    },

    async changeSport() {
      // Flag for when we increment the page. (No need to constantly re-fetch)
      this.sportChanged = true
    },

    async getSportInfo(globalSportId, filterAwardTypes) {
      await Promise.all([
        this.getSportAwardTypes(globalSportId, filterAwardTypes),
        this.getSportSpecificStatGroups(globalSportId)
      ])
    },

    async getSportAwardTypes(globalSportId, filterAwardTypes) {
      let sportAwardTypes = await this.$store.getters.awardTypesByGlobalSportId(globalSportId)
      const preseasonPoll = sportAwardTypes.find(f => f.title == 'Preseason Poll')
      sportAwardTypes.sort((a, b) => a.title.localeCompare(b.title))
      if(filterAwardTypes) {
        let maskedAwardTypeIdsSet = this.maskedAwardTypeIdsByGlobalSportId[globalSportId] != null
          ? this.maskedAwardTypeIdsByGlobalSportId[globalSportId]
          : new Set()
        sportAwardTypes = sportAwardTypes.filter(at => !maskedAwardTypeIdsSet.has(at.id))
      }
      
      if (this.selectedType == 4 && !sportAwardTypes.find(f => f.title == 'Preseason Poll')){
        sportAwardTypes.push(preseasonPoll)
      }
      this.sportAwardTypes = sportAwardTypes.map(awardType => {
        // Fields used internally that don't come from the API.
        return {
          ...awardType,
          selected: false,
        }
      })
    },

    async getSportSpecificStatGroups(globalSportId) {
      const res = await Controllers.StatGroupController.AdminListStatGroups(globalSportId)
      this.sportSpecificStatGroups = res.data.sort((a, b) => a.name.localeCompare(b.name))
    },

    async getInfoPage1() {
      // Gets info required to populate type, sport, season, division v-selects.
      // If editing, applies the user's selections.
      await Promise.all([
        this.$store.getters.ballotTypes.then(_ => this.ballotTypes = _),
        this.$store.getters.conferenceSports.then(_ => this.sportsList = _),
        this.$store.getters.conferenceSeasons.then(_ => this.seasonsList = _),
        this.$store.getters.conferenceDivisions.then(_ => this.divisionsList = _)
      ])

      // Editing
      if(this.ballot != null) {
        this.selectedType = this.ballot.ballotType
        this.selectedSport = this.ballot.globalSportId
        this.selectedSeason = this.ballot.season
        this.selectedDivision = this.ballot.division
        this.title = this.ballot.title
      }
    },

    async getInfoPage2() {
      // Gets users and sets checkbox state.
      // If editing, applies ballot state to checkboxes.
      const usersRes = await Controllers.UserController.AdminListUsers()

      if(usersRes && !usersRes.hasError) {
        let users = usersRes.data.filter(user => !user.disabled)

        // Set the checkboxes for each user. (and each group)
        const checkboxGroupsDict = {}
        users.forEach(user => {
          user.canVote = false
          user.canNominate = false
          user.canBoth = false

          if(checkboxGroupsDict[user.school] == null)
            checkboxGroupsDict[user.school] = {
              canNominate: false,
              canVote: false,
              canBoth: false,
              users: [],
            }

          checkboxGroupsDict[user.school].users.push(user)
          user.checkboxGroup = checkboxGroupsDict[user.school]
        })
        
        // Editing
        if(this.ballot != null) {
          // Add the nominators.
          this.ballot.nominators
            .filter(nominator => !nominator.disabled)
            .forEach(nominator => {
              const user = users.find(user => user.id == nominator.id)
              user.canNominate = true
            })

          // Add the voters.
          this.ballot.voters
            .filter(voter => !voter.disabled)
            .forEach(voter => {
              const user = users.find(user => user.id == voter.id)
              user.canVote = true
            })
          
          // Sync up the user 'Both' checkboxes.
          users.forEach(user => {
            user.canBoth = user.canNominate && user.canVote
          })
        }

        this.usersList = users
        this.checkboxGroups = Object.values(checkboxGroupsDict)
        this.updateGroupCheckboxes()
      }
      else {
        // Error handling
      }
    },

    async getInfoPage3() {
      // Gets ballot phases.
      // If editing, gets award types + stat groups and sets ballot phase state.
      // TODO: it does more
      const ballotPhases = await this.$store.getters.ballotPhases
      ballotPhases.forEach(bp => bp.enabled = false)

      // Gather awardTypeMasks into sets based on their globalSportId.
      const res = await Controllers.AwardTypeMaskController.AdminListAwardTypeMasks()
      const masks = res.data
      const maskedAwardTypeIdsByGlobalSportId = {}
      masks.forEach(mask => {
        if(maskedAwardTypeIdsByGlobalSportId[mask.globalSportId] == null)
          maskedAwardTypeIdsByGlobalSportId[mask.globalSportId] = new Set()
        
        maskedAwardTypeIdsByGlobalSportId[mask.globalSportId].add(mask.awardTypeId)
      })
      this.maskedAwardTypeIdsByGlobalSportId = maskedAwardTypeIdsByGlobalSportId
     
      // Editing
      if(this.ballot != null) {
        // Award types and stat groups for the sport.
        await this.getSportInfo(this.ballot.globalSportId, false)

        // Select award types and stat groups from the ballot.
        this.ballot.awards.forEach(award => {
          const awardType = this.sportAwardTypes.find(at => at.id == award.awardType.id)
          let item = []
          award.voteRank.forEach((vR) =>{
            item.push({
              id: vR.id,
              points: vR.points,
              rank: vR.rank
            })
          })
          award.VoteRank = item
          awardType.selected = true
        })

        // Filter out award types which are masked and non-selected
        let maskedAwardTypeIdsSet = this.maskedAwardTypeIdsByGlobalSportId[this.ballot.globalSportId] != null
          ? this.maskedAwardTypeIdsByGlobalSportId[this.ballot.globalSportId]
          : new Set()
        this.sportAwardTypes = this.sportAwardTypes.filter(at => at.selected || !maskedAwardTypeIdsSet.has(at.id))

        this.awards = this.ballot.awards

        // Games Occuring Between (TODO: make sure that timezone is respected)
        this.gamesBetweenStartDate = moment.utc(this.ballot.gamesOccuringBetweenStartDate).tz("America/New_York").toDate()
        this.gamesBetweenEndDate = moment.utc(this.ballot.gamesOccuringBetweenEndDate).tz("America/New_York").toDate()

        // Options
        this.rankVotes = this.ballot.rankVotes
        this.allowVotesForOwnTeam = this.ballot.allowVotesForOwnTeam
        this.showNominationExpands = this.ballot.showNominationExpands
        this.numDisplayedNominations = this.ballot.numDisplayedNominations
        this.confOnly = this.ballot.confOnly

        // Custom Nomination Field
        this.useCustomField = this.ballot.useCustomField
        this.customFieldLabel = this.ballot.customFieldLabel

        // Display Text (Nominations and Voting)
        this.nominationsPageDisplayText.editorData = this.ballot.nominationsPageDescription
        this.votingPageDisplayText.editorData = this.ballot.votingPageDescription

        // Ballot Phases
        if(this.ballot.phaseManuallyOverriden)
          ballotPhases.find(phase => phase.value == this.ballot.overridePhase).enabled = true

        this.nominationPhaseStartDate = this.ballot.nominationPhaseStartDate == null ? null : moment.utc(this.ballot.nominationPhaseStartDate).tz("America/New_York").toDate()
        this.nominationPhaseEndDate = this.ballot.nominationPhaseEndDate == null ? null : moment.utc(this.ballot.nominationPhaseEndDate).tz("America/New_York").toDate()
        this.votingPhaseStartDate = this.ballot.votingPhaseStartDate == null ? null : moment.utc(this.ballot.votingPhaseStartDate).tz("America/New_York").toDate()
        this.votingPhaseEndDate = this.ballot.votingPhaseEndDate == null ? null : moment.utc(this.ballot.votingPhaseEndDate).tz("America/New_York").toDate()
      }

      this.ballotPhaseOverrides = ballotPhases
    },

  },

  async created() {
    if(this.ballotId != null) {
      // The form is being used to edit an existing ballot.
      this.$root.updateTitle("Edit Award")
      const ballotRes = await Controllers.BallotController.AdminGetBallot(this.ballotId)
      
      if(ballotRes && !ballotRes.hasError) {
        this.ballot =  ballotRes.data
      }
      else {
        // Error handling
      }
    }
    else {
      // This form is being used to create a new ballot.
      this.$root.updateTitle("Add an Award")
    }

    await Promise.all([
      this.getInfoPage1(),
      this.getInfoPage2(),
      this.getInfoPage3(),
    ])
    this.userSchoolsCopy = this.userSchools.map(us => {
      return {
        selected: false,
        title: us,
        note: ''
      }
    })
    this.loaded = true
  },
}
</script>

<style lang="scss">
.add-award-checkbox .v-input--selection-controls__ripple {
  margin-right: 0 !important;
}

.add-award-checkbox .v-input--checkbox {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.add-award-checkbox .v-input--selection-controls__input {
  margin-right: 0 !important;
}

.v-stepper__content {
  padding: 0 !important;

  .v-stepper__wrapper {
    padding: 24px 24px 16px 24px !important;
  }
}
</style>